



import * as React from "react"
import Layout from "../components/layout"


const AboutPage = () => {
    return (
        <Layout pageTitle="About">

            <p>We are a licensed General Contractor and Owner’s Representation firm with over 40 years of residential and commercial project successes in the Napa-Sonoma region. </p>

            <p>We believe that success of any construction project is not only measured by the end result, but also by the dynamic of the building process itself.</p>

            <p>Ideally, that dynamic should include exceptional attention to detail, clear lines of communication, strong organizational skills, and fulfilled expectations.</p>

            <p>Deep knowledge and enduring connections allow us to deliver service and results that are thoughtful, efficient, effective, and of truly superior quality.</p>

            <p>In choosing Thornley Associates to be involved in your project, as either its General Contractor or Owner’s Representative, you are assured of achieving those goals.</p>

        </Layout>

    )
}

export default AboutPage
